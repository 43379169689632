import React from "react"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <div className="deco">
          <img
            src="/ellmauer_halt.jpg"
            alt="Ellmauer_Halt"
          />        
      </div>
      <div className="content">
        <h1>Willkommen bei alpinrecht.eu</h1>
        <p className="no-margin">
          alpinrecht.eu ist ein Service der{" "}
          <a
            href="http://www.kanzlei-schweikert.de"
            target="_blank"
            rel="noreferrer"
          >
            Kanzlei Schweikert
          </a>
        </p>
        <p>
          In naher Zukunft finden Sie hier ein Informationsportal über
          rechtliche Besonderheiten am, im und auf dem Berg.          
        </p>
        <p>
          Haben Sie rechtliche Fragen zu einem individuellen Sachverhalt, dann
          steht{" "}
          <a
            href="http://www.kanzlei-schweikert.de/cv"
            target="_blank"
            rel="noreferrer"
          >
            Rechtsanwalt Friedrich Schweikert
          </a>{" "}
          für Sie bereit.          
        </p>
        <p className="rem-of-16">
          Telefon: <b>08061 / 938 689 5</b>          
        </p>
      </div>
    </Layout>
  )
}
